import React from 'react';
import { graphql } from 'gatsby';
import MDXRenderer from 'gatsby-plugin-mdx/mdx-renderer';

import * as PAGES from '../constants/pages';
import * as UTIL from '../util/slug';
import Layout from '../components/Layout';
import Link from '../components/Link';
import Comments from '../components/Comments';

import {
  container,
  header,
  subheader,
  subheaderPrimary,
} from './post.module.scss';

export default function PostPage({
  location,
  data: { post, relatedPosts, site },
  pageContext: { categories },
}) {
  const contribute =
    post?.frontmatter?.contribute ||
    (site.siteMetadata.blog.contributeBase
      ? `${site.siteMetadata.blog.contributeBase}/${post.parent.relativePath}`
      : null);

  const slug = UTIL.getSlug(post.parent.relativePath);
  const author = post?.frontmatter?.author || site.siteMetadata.author;

  return (
    <Layout
      location={location}
      frontmatter={post.frontmatter}
      relatedPosts={relatedPosts}
      categories={categories}
      slug={slug}
      page={PAGES.POST}
    >
      {() => (
        <section
          itemScope
          itemType="http://schema.org/Article"
          className={container}
        >
          {/* Header */}
          <header className={header}>
            <h1 itemProp="headline">{post.frontmatter.title}</h1>
            <meta itemProp="name" content={post.frontmatter.title} />

            <span className={subheader}>
              <div className={subheaderPrimary}>
                <meta
                  itemProp="description"
                  content={post.frontmatter.description}
                />

                <time
                  itemProp="datePublished"
                  dateTime={post.frontmatter.dateShort}
                >
                  {post.frontmatter.dateLong}
                </time>
                <meta
                  itemProp="dateModified"
                  content={post.frontmatter.dateShort}
                />

                <meta itemProp="keywords" content={post.frontmatter.keywords} />
                <meta itemProp="wordCount" content={post.wordCount.words} />

                <span>
                  &nbsp;by&nbsp;
                  <span>{author}</span>
                  <meta itemProp="author" content={author} />
                </span>

                {/* Publisher */}
                <div
                  itemProp="publisher"
                  itemScope
                  itemType="https://schema.org/Organization"
                >
                  <meta itemProp="name" content={site.siteMetadata.title} />
                </div>

                {contribute && (
                  <span>
                    &nbsp;-&nbsp;<Link to={contribute}>Edit this Post</Link>
                  </span>
                )}
              </div>
            </span>
          </header>

          {/* Content */}

          <meta
            itemProp="mainEntityOfPage"
            content={site.siteMetadata.siteUrl + slug}
          />
          <article itemProp="articleBody">
            <MDXRenderer tableOfContents={post.tableOfContents}>
              {post.body}
            </MDXRenderer>
          </article>

          {/* Comments */}

          <Comments slug={slug} title={post.frontmatter.title} />
        </section>
      )}
    </Layout>
  );
}

export const pageQuery = graphql`
  query ($id: String!, $relatedIds: [String]) {
    site {
      siteMetadata {
        siteUrl
        author
        blog {
          contributeBase
        }
      }
    }
    post: mdx(fields: { id: { eq: $id } }) {
      tableOfContents
      frontmatter {
        title
        description
        dateLong: date(formatString: "MMMM DD, YYYY")
        dateShort: date(formatString: "YYYY-MM-DD")
        banner {
          childImageSharp {
            fluid(maxWidth: 900) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        keywords
        hashtags
        contribute
        author
      }
      wordCount {
        words
      }
      body
      parent {
        ... on File {
          relativePath
        }
      }
    }
    relatedPosts: allMdx(filter: { id: { in: $relatedIds } }) {
      edges {
        node {
          id
          excerpt(pruneLength: 200)
          frontmatter {
            title
            keywords
            categories
            banner {
              childImageSharp {
                fluid(maxWidth: 300) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          parent {
            ... on File {
              relativePath
            }
          }
        }
      }
    }
  }
`;
